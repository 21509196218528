<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Notices
            <v-spacer></v-spacer>
            <add-button
              @action="
                (selectedRole = []),
                  form.reset(),
                  (form.dialog = true),
                  $refs.form ? $refs.form.reset() : ''
              "
              permission="notice-create"
            >
              New Notice
            </add-button>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="pb-0 mb-0">
              <v-flex>
                <v-select
                  v-model="filter_param.role"
                  :disabled="roles.length < 1"
                  :items="[
                    { value: 'all', text: 'All' },
                    ...roles.map((item) => {
                      return { value: item.slug, text: item.name };
                    }),
                  ]"
                  class="pa-0"
                  label="Filter by role"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs3>
                <v-calendar-field
                  v-model="filter_param.start_date"
                  id-val="start_date_filter"
                  label="Filter by Start Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs3>
                <v-select
                  v-model="filter_param.status"
                  :disabled="roles.length < 1"
                  :items="[
                    { value: 'all', text: 'All' },
                    { value: 'pending', text: 'Pending' },
                    { value: 'live', text: 'Live' },
                    { value: 'expired', text: 'Expired' },
                  ]"
                  class="pa-0"
                  label="Filter by notice status"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <br />
          <div
            v-if="stats && Object.keys(stats).length > 0"
            class="data-represent"
          >
            <div>
              <span class="d-info"></span>&nbsp;
              <strong>{{ form.items.meta.total }}</strong>
              <small> Total Notice</small>
            </div>
            <div>
              <span class="d-success"></span> &nbsp;
              <strong>{{ stats.live }}</strong>
              <small> Sent / Live</small>
            </div>

            <div>
              <span class="d-warning"></span> &nbsp;
              <strong>{{ stats.pending }}</strong>
              <small> Pending</small>
            </div>

            <div>
              <span class="d-error"></span> &nbsp;
              <strong>{{ stats.expired }}</strong>
              <small> Expired</small>
            </div>
          </div>
          <br />
          <v-data-table
            :loading="loading"
            :headers="headers"
            sort-by="id"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td
                  :class="{
                    is_live: item.is_live,
                    is_expired: item.is_expired,
                    is_pending: item.is_pending,
                  }"
                >
                  {{ index + form.items.meta.from }}
                </td>
                <td>
                  <v-list-item-avatar v-if="item.image">
                    <img :src="item.image" alt="" />
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else
                    ><img :src="$auth.company().logo" alt=""
                  /></v-list-item-avatar>
                </td>
                <td class="text-xs-left">
                  <strong>{{ item.title }} </strong>
                  <p
                    style="
                      width: 80%;
                      margin-bottom: 0;
                      line-height: 1;
                      color: #999;
                    "
                  >
                    <small>{{ item.desc_slim }}</small>
                  </p>
                </td>
                <td class="text-xs-left">
                  <v-chip small label v-if="item.role === 'all'"
                    >{{ "All" }}
                  </v-chip>
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        style="cursor: pointer"
                        v-bind="attrs"
                        v-on="on"
                        small
                        label
                        >{{ parseRole(item.role) }}
                      </v-chip>
                    </template>
                    <span>{{ item.role.toUpperCase() }}</span>
                  </v-tooltip>
                </td>

                <!-- <td class="text-xs-left">{{ item.description }}</td> -->
                <td class="text-xs-left">
                  <v-chip
                    small
                    label
                    color="primary"
                    text-color="white"
                    class="mt-1"
                  >
                    {{ item.start_date }}
                  </v-chip>
                  <v-chip
                    small
                    label
                    color="orange"
                    text-color="white"
                    class="mt-1 mb-1"
                  >
                    {{ item.end_date }}
                  </v-chip>
                </td>
                <td class="text-xs-left">{{ item.days_ago }}</td>
                <td class="text-right">
                  <!-- v-if="today <= item.end_date" -->
                  <span>
                    <edit-button
                      permission="notice-update"
                      @agree="form.edit(item), updateRole(item)"
                    />
                    <delete-button
                      permission="notice-delete"
                      @agree="form.delete(item.id)"
                    />
                  </span>
                  <!-- <v-chip label v-else small text-color="#333">
                    Expired
                  </v-chip> -->
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" scrollable persistent max-width="850px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Notice Details</span>
          </v-card-title>
          <v-card-text class="pa-1">
            <v-form
              class="notice_form"
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-select
                      outlined
                      dense
                      v-model="selectedRole"
                      :items="roles"
                      label="Select Role*"
                      multiple
                      class="pt-0"
                      :rules="[(v) => !!v || 'Role Field is required']"
                    >
                      <v-list-item slot="prepend-item" ripple @click="toggle">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              selectedRole.length > 0 ? 'indigo darken-4' : ''
                            "
                            >{{ icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item>
                      <v-divider slot="prepend-item" class="mt-2"></v-divider>
                      <v-divider slot="append-item" class="mb-2"></v-divider>
                    </v-select>
                  </v-flex>
                  <v-flex xs12 v-if="selectedRole.length > 0">
                    <alert-message
                      v-if="selectAllRoles === true"
                      :extraSmall="true"
                      title="All Roles Selected"
                      message="Notice will get displayed to every user and will also get previewed in school's website and mobile application's home screen."
                    ></alert-message>

                    <alert-message
                      type="warning"
                      v-if="selectAllRoles === false"
                      :extraSmall="true"
                      :title="`${selectedRole.length} ${
                        selectedRole.length > 1 ? 'Roles' : 'Role'
                      } Selected`"
                      message="Notice will get displayed to user who have these selected role and will not get previewed in school's website and mobile application's home screen."
                    ></alert-message>
                    <br />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      outlined
                      dense
                      label="Notice Title*"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.title"
                      name="name"
                      :error-messages="form.errors.get('title')"
                      :rules="[(v) => !!v || 'Notice Title Field is required']"
                    />
                  </v-flex>

                  <v-flex xs3>
                    <v-calendar-field
                      :disablePreviousDate="true"
                      id-val="start_date"
                      v-model="form.start_date"
                      label="Start Date"
                      :rules="[(v) => !!v || 'Start Date Field is required']"
                    >
                    </v-calendar-field>
                  </v-flex>
                  <v-flex xs3>
                    <v-calendar-field
                      :disablePreviousDate="true"
                      id-val="end_date"
                      v-model="form.end_date"
                      label="End Date"
                      :rules="[(v) => !!v || 'End Date Field is required']"
                    >
                    </v-calendar-field>
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      v-if="isStartDateToday !== true"
                      outlined
                      dense
                      @click="timePickerModal = true"
                      v-model="form.trigger_at"
                      label="Schedule When"
                      readonly
                      class="pt-0"
                    >
                    </v-text-field>
                    <v-select
                      style="margin-top: -20px"
                      v-else
                      @change="timePickerOptChange"
                      outlined
                      dense
                      :items="[
                        { value: 'now', text: 'Now' },
                        { value: 'customize', text: 'Customize' },
                      ]"
                      label="Select When"
                      class="pt-0"
                    >
                    </v-select>
                    <v-dialog
                      ref="dialog"
                      v-model="timePickerModal"
                      :return-value.sync="form.trigger_at"
                      persistent
                      width="290px"
                    >
                      <!-- <template v-slot:activator="{ on, attrs }">
                          
                        </template> -->
                      <v-time-picker
                        :allowed-minutes="allowedMinutes"
                        v-if="timePickerModal"
                        v-model="form.trigger_at"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="timePickerModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(form.trigger_at)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs2>
                    <v-chip
                      style="margin-top: -20px"
                      @click="timePickerModal = true"
                      v-if="
                        form.trigger_at &&
                        form.trigger_at !== 'now' &&
                        isStartDateToday
                      "
                      class="primary"
                      label
                      ><b>{{ form.trigger_at }}</b></v-chip
                    >
                  </v-flex>
                  <label for="">
                    Crop Image For Preview
                  </label>
                      <!-- :ratio="16 / 6" -->
                  <v-flex xs12>
                    <image-picker
                      v-model="form.image"
                      uiType="dragger"
                      @result="cropImage"
                      :compressImage="true"
                      :ratio="16 / 6"
                      @fileOriginal="originalFile"
                      @onRemove="onRemove()"
                    ></image-picker>

                    <br />
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12>
                    <wysiwyg v-model="form.description" :height="800" />
                  </v-flex>
                  <v-flex xs12>
                    <br />
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs6>
                    <v-checkbox
                      style="margin-right: 20px"
                      color="blue"
                      v-model="form.fcm"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Deliver FCM Notification
                          <p style="line-height: 10px">
                            <small style="color: #666; font-size: 10px">
                              A notification will be delivered to the user on
                              their mobile devices informing about this notice.
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex xs6>
                    <v-checkbox
                      :disabled="selectAllRoles === false"
                      style="margin-right: 20px"
                      color="blue"
                      v-model="form.chat_group"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Group Chat Alert
                          <p style="line-height: 10px">
                            <small style="color: #666; font-size: 10px">
                              A telegram message will be sent on the school's
                              telegram group manage by eAcademynepal. (available
                              only for all roles)
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex xs6>
                    <v-checkbox
                      style="margin-right: 20px"
                      color="blue"
                      v-model="form.display_notice"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Display Notice Pop Up
                          <p style="line-height: 10px">
                            <small style="color: #666; font-size: 10px">
                              Notice are displayed on a pop up alert in school's website and app.
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex xs6>
                    <v-checkbox
                      style="margin-right: 20px"
                      color="blue"
                      v-model="form.persistent_pop_up"
                    >
                      <template v-slot:label>
                        <div style="color: #333">
                          Persistent Pop Up
                          <p style="line-height: 10px">
                            <small style="color: #666; font-size: 10px">
                              If enabled notice will pop up untilll the end date
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset(), resetImagePicker()"
              >Close</v-btn
            >
            <v-btn color="success" :loading="saving" text @click="validate"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { objToQueryString, validateDate } from "../../../../library/helpers";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
// const year = parseInt(nd.getYear()) + 1;
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    cropperImg: {
      cropDialog: false,
      imgSrc: "",
      cropImg: "",
      uploadCropped: {},
      name: null,
    },
    valid: true,
    lazy: false,
    timePickerModal: false,
    form: new Form(
      {
        title: "",
        description: "",
        role: "",
        image: "",
        trigger_at: "",
        start_date: nd.format("YYYY-MM-DD"),
        end_date: nd.format("YYYY-MM-"),
        fcm: true,
        chat_group: false,
        display_notice: true,
        persistent_pop_up: false,
        full_image:'',
      },
      "/api/notice"
    ),
    search: null,
    selectedRole: [],
    roles: [],
    roleLoading: false,
    loading: false,
    pagination: {
      descending: true,
    },
    today: nd.format("YYYY-MM-DD"),
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    headers: [
      { text: "#", align: "center", value: "id", width: 30, sortable: false },
      {
        text: "Image",
        align: "center",
        value: "id",
        width: 50,
        sortable: false,
      },
      { text: "Title", align: "left", value: "title", sortable: false },
      { text: "Role", align: "left", value: "role", sortable: false },

      { text: "Start/End Date", align: "left", sortable: false, width: 75 },
      { text: "Time ago", align: "left", sortable: false, width: 150 },
      { text: "Action", align: "right", sortable: false, width: 75 },
    ],
    filter_param: {
      role: "",
      start_date: "",
      status: "",
    },
    saving: false,
    stats: {},
  }),

  computed: {
    ...mapState(["batch"]),
    selectAllRoles() {
      return this.selectedRole.length === this.roles.length;
    },
    selectFewRoles() {
      return this.selectedRole.length > 0 && !this.selectAllRoles;
    },
    icon() {
      if (this.selectAllRoles) return "check_box";
      if (this.selectFewRoles) return "indeterminate_check_box";
      return "add_box";
    },
    isStartDateToday() {
      return this.today === this.form.start_date;
    },
  },
  watch: {
    pagination: function () {
      this.get();
    },
    "filter_param.role"() {
      this.get();
    },
    "filter_param.status"() {
      this.get();
    },

    "filter_param.start_date"() {
      this.get();
    },
    "form.start_date"() {
      this.form.trigger_at = "";
    },
    selectAllRoles(val) {
      if (val === false) {
        this.form.chat_group = false;
      }
    },
  },
  mounted() {
    this.fetchRoles();
    
  },

  methods: {
    onRemove(){
      this.form.image = null;
      // console.log('onRemove callled')
    },
    parseRole(role) {
      if (!role) return false;
      return role.split(",").length === 1
        ? role.toUpperCase()
        : role.split(",").length + " Roles";
    },
    timePickerOptChange(val) {
      if (val === "customize") {
        this.form.trigger_at = "";
        this.timePickerModal = true;
      } else {
        this.form.trigger_at = val;
      }
    },
    originalFile(data){
      this.form.full_image = data;
    },
    cropImage(data) {
      this.cropperImg.cropImg = data.image;
      this.cropperImg.uploadCropped = data.cropped;
    },
    allowedMinutes(v) {
      return v % 10 === 0;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.store();
      }
    },
    fetchRoles() {
      this.roleLoading = true;
      this.$rest
        .get("/api/roles?rowsPerPage=50&sortBy=name")
        .then(({ data }) => {
          let role = [];
          data.data.map(function (item) {
            role.push({
              id: item.id,
              slug: item.slug,
              name: item.name,
              text: item.name,
              value: item.id,
            });
          });
          this.roles = role;
        })
        .finally(() => {
          this.roleLoading = false;
        });
    },

    get(params) {
      this.loading = true;
      const filter = objToQueryString(this.filter_param);
      let query = [null, undefined].includes(params)
        ? this.queryString(`descending=true&${filter}`)
        : params;
      if (Object.keys(this.stats).length === 0) {
        query += "&withStats=true";
      }
      this.form
        .get(null, query)
        .then(({ data }) => {
          this.pagination.totalItems = data.meta.total;
          if (Object.keys(data.stats)) {
            this.stats = data.stats;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    store() {
      this.form.image = this.cropperImg.uploadCropped;
      this.form.start_date = this.form.start_date.dateForm();
      this.form.end_date = this.form.end_date.dateForm();

      if (this.selectedRole.length === this.roles.length)
        this.form.role = "all";
      else {
        let selectedRoleSlug = this.roleSlugFetch();

        this.form.role = selectedRoleSlug.join(", ");
      }
      this.saving = true;
      this.form
        .store()
        .then(() => {
          this.resetImagePicker();
        })
        .finally(() => {
          this.saving = false;
        });
    },
    roleSlugFetch() {
      let selectedRoleSlug = [];

      this.roles.map((item) => {
        if (this.selectedRole.includes(item.id)) {
          selectedRoleSlug.push(item.slug);
        }
      });
      return selectedRoleSlug;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllRoles) {
          this.selectedRole = [];
        } else {
          this.selectedRole = this.roles.map((data) => {
            return data.value;
          });
        }
      });
    },

    resetImagePicker() {
      this.cropperImg = {
        cropDialog: false,
        imgSrc: "",
        cropImg: "",
        uploadCropped: {},
        name: null,
      };
    },
    onPickFile() {
      this.$refs.image.click();
    },
    roleChanged(item) {
      this.selectedRole = this.selectedRole.filter(function (item) {
        return typeof item === "object";
      });
    },
    updateRole(item) {
      if (item.role) {
        let $this = this;
        $this.selectedRole = [];
        if (item.role === "all") {
          this.roles.map(function (role) {
            $this.selectedRole.push(role.id);
          });
        } else {
          const roles = item.role.split(", ");
          this.selectedRole = [];
          this.roles.map(function (role) {
            if (roles.includes(role.slug)) {
              $this.selectedRole.push(role.id);
            }
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.notice_form {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
    color: white;
  }
}
.editr--content {
  min-height: 280px;
}
.is_live,
.is_pending,
.is_expired {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 4%;
    left: 0;
    height: 92%;
    width: 5px;
  }
}

.is_live {
  &:after {
    background: #4caf50;
  }
}
.is_expired {
  &:after {
    background: #ff5252;
  }
}
.is_pending {
  &:after {
    background: #ff9800;
  }
}
</style>
